<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.scores") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <el-button
          v-can="'payment_types.create'"
          size="mini"
          @click="drawer.create.status = true"
        >
          {{ $t("message.create") }}
        </el-button>
        <!-- <export-excel
                    v-can="'paymentTypes.excel'"
                    class="btn excel_btn"
                    :data="list"
                    :fields="excel_fields"
                    @fetch="controlExcelData()"
                    worksheet="Тип_оплаты"
                    name="Тип_оплаты.xls"
                >
                    <el-button size="mini">
                        <i class="el-icon-document-delete"></i> Excel
                    </el-button>
                </export-excel> -->
        <crm-column-settings
          :columns="columns"
          :modelName="'paymentTypes'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>

    <table class="table table-bordered table-hover" v-loading="loadingData">
      <thead>
        <tr>
          <th class="w50p" v-if="columns.id.show">
            {{ columns.id.title }}
          </th>

          <th v-if="columns.name.show">
            {{ columns.name.title }}
          </th>

          <th v-if="columns.mfo.show">
            {{ columns.mfo.title }}
          </th>

          <th v-if="columns.currency_id.show">
            {{ columns.currency_id.title }}
          </th>

          <th v-if="columns.number.show">
            {{ columns.number.title }}
          </th>


          <th v-if="columns.incoming.show">
            {{ columns.incoming.title }}
          </th>

          <th v-if="columns.outgoing.show">
            {{ columns.outgoing.title }}
          </th>

          <th v-if="columns.balance.show">
            {{ columns.balance.title }}
          </th>

          <th v-if="columns.created_at.show">
            {{ columns.created_at.title }}
          </th>

          <th v-if="columns.updated_at.show">
            {{ columns.updated_at.title }}
          </th>

          <th v-if="columns.settings.show">
            {{ columns.settings.title }}
          </th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="user in list" :key="user.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ user.id }}</td>

          <td v-if="columns.name.show">
            {{ user.name }}
          </td>

          <td v-if="columns.mfo.show">
            {{ user.mfo }}
          </td>

          <td v-if="columns.currency_id.show">
            {{ user.currency.name }}
          </td>

          <td v-if="columns.number.show">
            {{ user.number }}
          </td>

          <td v-if="columns.incoming.show">
            {{ user.balance }}
          </td>

          <td v-if="columns.outgoing.show">
            {{ user.outgoing }}
          </td>

          <td v-if="columns.balance.show">
            {{ parseInt(user.balance - user.outgoing) }}
          </td>

          <td v-if="columns.created_at.show">
            {{ user.created_at }}
          </td>

          <td v-if="columns.updated_at.show">
            {{ user.updated_at }}
          </td>

          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="user"
              name="paymentTypes"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-drawer
      size="60%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>
    <el-drawer
      size="60%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
    <!-- <el-drawer
      class="right-modal"
      :with-header="false"
      :append-to-body="true"
      :visible.sync="appModal"
      size="50%"
    >
      <CrmCreate />
    </el-drawer> -->
  </div>
</template>

<script>
// @ is an alias to /src
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import drawer from "@/utils/mixins/drawer";
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [list, drawer],
  name: "scores",
  components: {
    CrmCreate,
    CrmUpdate,
    // Pagination,
  },

  data() {
    return {
      appModal: false,
      sarcheBlocks: "",
      selectedModel: null,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "scores/list",
      columns: "scores/columns",
      pagination: "scores/pagination",
      filter: "scores/filter",
      sort: "scores/sort",
      cities: "cities/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    if (this.cities && this.cities.length === 0) await this.loadCities();
  },
  methods: {
    ...mapActions({
      updateList: "scores/index",
      updateSort: "scores/updateSort",
      updateFilter: "scores/updateFilter",
      updateColumn: "scores/updateColumn",
      updatePagination: "scores/updatePagination",
      editModel: "scores/show",
      empty: "scores/empty",
      delete: "scores/destroy",
      refreshData: "scores/refreshData",
      loadCities: "scores/index",
    }),
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

